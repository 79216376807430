<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="skus"
      class="border-color"
      disable-pagination
      hide-default-footer
      :height="heigthSkus"
      item-key="uuid"
      fixed-header
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between px-4 pt-2">
          <div class="h6">SKUs</div>
          <v-spacer />
          <v-btn @click="addSku" color="primary" small text>
            <v-icon left>mdi-plus</v-icon>
            Sku
          </v-btn>
        </div>
      </template>
      <template v-slot:item.sku="{ item }">
        <v-text-field
          v-model="item.sku"
          dense
          class="text__text-center"
        />
      </template>
      <template v-slot:item.min_stock="{ item }">
        <v-text-field
          v-model="item.min_stock"
          dense
          type="number"
          class="text__text-center"
        />
      </template>
      <template v-slot:item.max_stock="{ item }">
        <v-text-field
          v-model="item.max_stock"
          dense
          type="number"
          class="text__text-center"
        />
      </template>
      <template v-slot:item.inventory_initial="{ item }">
        <v-text-field
          v-model="item.inventory_initial"
          dense
          type="number"
          class="text__text-center"
          :readonly="item.id !== -1"
        />
      </template>
      <template v-slot:item.description="{ item }">
        <v-text-field
          v-model="item.description"
          dense
        />
      </template>
      <template v-slot:item.barcodes="{ item }" >
        <v-btn @click="editBarcodes(item)" small color="success" icon>
          <v-icon>mdi-barcode</v-icon>
          {{ item.barcodes.length }}
        </v-btn>
      </template>
      <template v-slot:item.prices="{ item }">
        <v-btn @click="editPrices(item)" small color="success" icon>
          <v-icon>mdi-currency-usd</v-icon>
          {{ item.priceables.length }}
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn @click="removeItem(item)" small color="red" icon>
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        <!-- <v-btn @click="editItem(item)" small color="accent" icon v-if="item.id !== -1">
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn> -->
        <!-- <v-btn @click="saveItem(item)" small color="success" v-if="productId !== -1">
          <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
        </v-btn> -->
      </template>
    </v-data-table>
    <Priceables
      ref="priceables"
      :prices="prices"
      :packages="packages"
    />
    <Barcodes
      ref="barcodes"
    />
  </div>
</template>

<script>
// services
import PriceServices from '@/store/services/general/PriceServices';
import PackageServices from '@/store/services/warehouse/PackageServices';
import SkuServices from '@/store/services/inventory/SkuServices';

// components
// import Barcode from '@/components/commons/barcodes/CardBarcode';
import Priceables from '@/views/general/priceable/PriceablesCU';
import Barcodes from '@/views/general/barcode/BarcodesCU';
import { uuid } from '@/constants/helpers';
import { mapGetters } from 'vuex';
export default {
  props: {
    productId: {
      type: Number,
      default: -1,
    }
  },
  components: {
    // Barcode,
    Priceables,
    Barcodes,
  },
  data() {
    return {
      skus: [],

      currentSku: {
        uuid: '',
      },

      prices: [],
      packages: [],

      heigthSkus: window.innerHeight - 400,
      loading: false
    };
  },
  created() {
    this.getPrices();
    this.getPackages();
  },
  methods: {
    addSku() {
      this.skus.unshift({
        id: -1,
        uuid: uuid(),
        sku: `sku-${Math.round(Math.random() * 999 + 99999)}`,
        description: '',
        min_stock: 0,
        max_stock: 0,
        inventory_initial: 0,
        barcodes: [],
        priceables: [],
      });
    },
    removeItem(item) {
      const index = this.skus.findIndex((sku) => sku.sku === item.sku);
      this.skus.splice(index, 1);
    },
    editBarcodes(item) {
      const barcodes = this.$refs['barcodes'];
      barcodes.sku = item;
      barcodes.barcodes = item.barcodes;
      barcodes.dialog = true;
    },
    editPrices(item) {
      const priceables = this.$refs['priceables'];
      priceables.sku = item;
      // this.priceables = [...this.sku.prices, ...[]];
      priceables.priceables = item.priceables;
      priceables.dialog = true;
    },
    async getPrices() {
      try {
        const response = await PriceServices.index(this.user.archon.id);
        this.prices = response.data.data;
      } catch (error) {
        this.$notify({
          group: 'petition',
          title: 'Precios',
          type: 'error',
          text: error,
        });
      }
    },
    async getPackages() {
      try {
        const response = await PackageServices.index(this.user.archon.id);
        this.packages = response.data.data;
      } catch (error) {
        this.$notify({
          group: 'petition',
          title: 'Paquetes',
          type: 'error',
          text: error,
        });
      }
    },
    getSkus() {
      console.log('getSkus')
    },
    async editItem(value) {
      console.log(value)
      try {
        this.loading = true
        await SkuServices.update(this.user.archon.id, value.id, value)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$notify({
          group: 'petition',
          title: 'Skus',
          type: 'error',
          text: error,
        });
      }
    },
    async saveItem(value) {
      try {
        this.loading = true
        const index = this.skus.indexOf(value)
        // let newData = []
        // newData.push = value
        console.log(index)
        value.product_id = this.productId
        console.log(value)
        const response = await SkuServices.store(this.user.archon.id, value)
        value.id = response.data.id
        this.skus.splice(index, 1, value)
        console.log(this.skus)
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$notify({
          group: 'petition',
          title: 'Skus',
          type: 'error',
          text: error,
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: 'Sku',
          sortable: false,
          value: 'sku',
          width: '20%',
        },
        {
          text: 'Desc.',
          value: 'description',
          sortable: false,
          width: '35%',
        },
        // {
        //   text: 'Stock Min.',
        //   value: 'min_stock',
        //   sortable: false,
        //   width: '10%',
        // },
        // {
        //   text: 'Stock Max.',
        //   value: 'max_stock',
        //   sortable: false,
        //   width: '10%',
        // },
        { text: 'Inv. Inicial', value: 'inventory_initial', sortable: false, width: '20%', },
        {
          text: 'Códigos de Barra',
          value: 'barcodes',
          sortable: false,
          width: '5%',
        },
        { text: '', value: 'prices', sortable: false, width: '5%' },
        { text: '', value: 'actions', sortable: false, width: '5%' },
      ];
    },
  },
  watch: {
    skus() {
      this.$emit('input', this.skus);
    },
  },
};
</script>

<style scoped>
.text__text-center >>> input {
  text-align: center;
}
.border-color {
  border: 1px solid #E0E0E0;
  border-radius: 5px;
}
</style>