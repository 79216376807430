<template>
  <v-card outlined height="155">
    <v-card-text>
      <v-text-field
        v-model="name"
        label="Producto"
        dense
        :rules="[isRequired]"
        :error-messages="errorName"
      />
      <v-textarea
        v-model="description"
        label="Descripcion"
        rows="2"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      default: null
    }
  },
  data() {
    return {
      name: '',
      description: '',

      // rules
      isRequired: value => !!value || 'Este campo es obligatorio',

      //errors
      errorName: '',
    };
  },
  methods: {
    setErrors(errors) {
      this.errorName = errors?.errorName || '';
    },
    sendProduct() {
      let brandId = -1
      if (this.product) {
        brandId = this.product.brand_id
      }
      this.$emit('input', {
        name: this.name,
        description: this.description,
        brand_id: brandId,
        type: 'PRODUCT',
        status: 'ACTIVE',
      });
    },
    setValues() {
      if (this.product) {
        this.name = this.product.name
        this.description = this.product.description
      }
    }
  },
  watch: {
    name() {
      this.sendProduct();
    },
    description() {
      this.sendProduct();
    },
    product: {
      deep: true,
      immediate: true,
      handler: 'setValues'
    }
  },
}
</script>