<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-end mb-2">
      <v-btn
        small
        @click="storeProduct"
        color="primary"
        :disabled="!validateSkus"
      >
        Guardar
      </v-btn>
    </div>
    <v-form ref="form" onsubmit="return false;">
      <v-row>
        <!-- <v-col cols="12" md="6" sm="12">
          <Images
            :images="images"
          />
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <ContainerBarcode
            :barcodes="barcodes"
          />
        </v-col> -->
        <v-col cols="12" md="4" sm="12">
          <ProductForm
            v-model="product"
            ref="productForm"
            :product="product"
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <SelectBrands
            v-model="currentBrand"
            :rules="[isRequiredObj]"
          />
          <!-- :brandId="product.brand_id" -->
          <div class="mt-1"/>
          <SelectCategories
            v-model="categories"
            :productCategories="productCategories"
          />
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-card outlined class="pa-3">
            <div class="d-flex justify-space-between">
              <SelectSupplier
                ref="selectEntities"
                v-model="supplier"
                label="Proveedor"
                outlined
                :rules="[isRequiredObj]"
                include="entity"
                type-entity="SUPPLIER"
                :entity="supplier"
              />
              <v-btn
                color="primary"
                outlined
                small
                rounded
                icon
                class="ml-2"
                @click="dialogEntity = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <SkuCreate
            v-model="skus"
            ref="skuCreate"
            :productId="productId"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogEntity"
      width="800"
      persistent
    >
      <!-- <EntityCreate
        :isSupplier="true"
        @onContactSave="contactSaved"
        @onCancel="dialogEntity = false"
        include="contactable.supplier"
      /> -->
      <SupplierForm
        @onCreated="contactSaved"
        @onCancel="dialogEntity = false"
      />
    </v-dialog>
  </div>
</template>

<script>
// components
// import Images from '@/components/commons/image/Images';
// import ContainerBarcode from '@/components/commons/barcodes/ContainerBarcode';
// import EntityCreate from '@/views/general/entity/EntityCreate'
import ProductForm from './ProductForm';
import SkuCreate from '@/views/inventory/sku/SkuCreate';
import SelectBrands from '@/views/inventory/brand/SelectBrand';
import SelectCategories from '../category/SelectCategories';
// import SelectEntities from '../../../components/commons/SelectEntities';
import SelectSupplier from '../../../components/commons/SelectSupplier';
// services
import ProductServices from '@/store/services/inventory/ProductServices';
import SkuServices from '@/store/services/inventory/SkuServices';
import BarcodeServices from '@/store/services/general/BarcodeServices';
import PriceableServices from '@/store/services/general/PriceableServices';
import InventoryServices from '@/store/services/inventory/InventoryServices';
//helpes
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
import SupplierForm from '../../general/supplier/SupplierForm.vue';
export default {
  components: {
    // Images,
    // ContainerBarcode,
    ProductForm,
    SkuCreate,
    SelectBrands,
    SelectCategories,
    SelectSupplier,
    // EntityCreate,
    SupplierForm
  },
  props: {
    productEdit: {
      default: null
    }
  },
  data() {
    return {
      images: [],

      loading: false,

      // skus
      skus: [],
      currentBrand: {},
      product: {
        id: null,
        name: '',
        description: '',
        brand_id: -1,
      },
      supplier: null,
      categories: [],
      productCategories: null,
      productSupplier: null,
      //rules
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',

      dialogEntity: false,
      productId: null
    };
  },
  methods: {
    contactSaved(data) {
      const supplier = Object.assign({}, data.supplier);
      // this.supplier = supplier
      this.$refs['selectEntities'].suppliers.push(supplier);
      this.$refs['selectEntities'].selected = supplier;
      this.dialogEntity = false;
    },
    // product
    async storeSkus(productId) {
      try {
        const skus = this.skus.map(sku => ({
          product_id: productId,
          sku: sku.sku,
          min_stock: sku.min_stock,
          max_stock: sku.max_stock,
          description: sku.description,
        }));
        this.loading = true;
        const response = await SkuServices.storeBulk(this.user.archon.id, {
          skus,
        });
        let priceables = [];
        let inventories = [];
        let barcodes = [];
        response.data.forEach(respSku => {
          const index = this.skus.findIndex(sku => sku.sku === respSku.sku);
          const newPriceables = this.skus[index].priceables.map(priceable => ({
            price_id: priceable.price_id,
            value: priceable.value,
            package_id: priceable.package_id,
            units: priceable.units,
            priceable_type: 'sku',
            priceable_id: respSku.id,
          }));
          priceables = [...priceables, ...newPriceables];
          const newBarcodes = this.skus[index].barcodes.map(barcode => ({
            value: barcode.value,
            format: null,
            barcodable_id: respSku.id,
            barcodable_type: 'sku',
            // configurations: {},
          }));
          barcodes = [...barcodes, ...newBarcodes];

          if (this.skus[index].inventory_initial > 0  && !this.productEdit) {
            inventories.push({
              sku_id: respSku.id,
              price: this.getPriceBuy(this.skus[index].priceables),
              quantity: this.skus[index].inventory_initial,
            });
          }
        });
        if (priceables.length > 0 && !this.productEdit) {
          await PriceableServices.storeBulk(this.user.archon.id, {
            priceables,
          });
        }
        if (inventories.length > 0 && !this.productEdit) {
          await InventoryServices.storeBulk(this.user.archon.id, this.user.warehouse.id, {
            inventories,
          });
        }
        if (barcodes.length > 0) {
          await BarcodeServices.storeBulk(this.user.archon.id, {
            barcodes,
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    getPriceBuy(priceables) {
      const index = priceables.findIndex(priceable => priceable.price_type === 'BUY' && priceable.package_name === 'CAJA');
      return index === -1 ? 0 : (priceables[index].value / priceables[index].units);
    },
    async storeProduct() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.$refs['productForm'].setErrors({});
          this.product.brand_id = this.currentBrand.id;
          this.product['categories_id'] = this.categories;
          this.product['supplier_id'] = this.supplier.id;
          let response = []
          if (this.productEdit) {
            response = await ProductServices.update(this.user.archon.id, this.productEdit.id, this.product);
          } else {
            response = await ProductServices.store(this.user.archon.id, this.product);
          }
          await this.storeSkus(response.data.id);
          this.goList();
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          if (error.response.status === 422) {
            const errorName = error.response.data.errors?.name[0] || null;
            if (errorName) {
              this.$refs['productForm'].setErrors({
                errorName,
              });
            }
          }
        }
      }
    },
    goList() {
      this.$router.push({
        name: 'skus',
      });
    },
    setProductValues() {
      if (this.productEdit) {
        console.log(this.productEdit.suppliers)
        this.productId = this.productEdit.id
        this.getProductCategories()
        this.product.name = this.productEdit.name
        this.product.description = this.productEdit.description
        this.product.brand_id = this.productEdit.brand_id
        this.supplier = this.productEdit.suppliers[0]
      }
    },
    async getProductCategories() {
      try {
        this.loading = true;
        const params = {
          include: 'suppliers.entity.contactable,skus.priceables'
        }
        const response = await ProductServices.show(this.user.archon.id, this.productEdit.id, params);
        console.log(response.data)
        this.productCategories = response.data.categories;
        this.productSupplier = response.data.suppliers;
        this.$refs.skuCreate.skus = response.data.skus;
        this.$refs.skuCreate.priceables = response.data.skus.priceables;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          group: 'petition',
          title: 'Paquetes',
          type: 'error',
          text: error,
        });
      }
    },
    async getSkus() {
      try {
        const params = {
          order_by: 'created_at',
          order_direction: 'DESC',
          search: `product_id:${this.productEdit.id}`,
        }
        const response = await SkuServices.index(this.productEdit.id, params);
        console.log(response)
        // this.packages = response.data.data;
      } catch (error) {
        this.$notify({
          group: 'petition',
          title: 'Paquetes',
          type: 'error',
          text: error,
        });
      }
    },
  },
  watch: {
    productEdit: {
      deep: true,
      immediate: true,
      handler: 'setProductValues'
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    validateSkus() {
      const skusValidated = this.skus.filter(sku => (
        sku.sku.length > 3 &&
        sku.min_stock !== '' &&
        sku.max_stock !== '' &&
        sku.box_qty !== ''
      ));
      return skusValidated.length === this.skus.length && this.skus.length > 0;
    },
  },
}
</script>