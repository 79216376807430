<template>
  <v-card outlined class="px-3 pt-3 pb-1">
    <div class="d-flex justify-space-between">
      <v-select
        :label="$t('category')"
        v-model="categoriesSelected"
        :items="categories"
        item-text="name"
        item-value="id"
        :loading="loading"
        :multiple="multiple"
        dense
        chips
      />
      <v-btn
        color="primary"
        outlined
        small
        @click="dialog = !dialog"
        rounded
        icon
        class="ml-2"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
     
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <CategoryForm
        @onCategorySaved="categorySaved"
        @onCancel="cancelSaveCategory"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import CategoryServices from "@/store/services/general/CategoryServices";
import CategoryForm from "../../../components/category/CategoryForm";
import { mapGetters } from "vuex";
export default {
  components: {
    CategoryForm
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true
    },
    productCategories: {
      default: null
    }
  },
  data() {
    return {
      categories: [],
      categoriesSelected: [],
      dialog: false,
      loading: false,
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        this.categories = [];
        this.categoriesSelected = [];
        const params = {
          order_by: "created_at",
          order_direction: "DESC",
        };
        this.loading = true;
        const response = await CategoryServices.index(this.user.archon.id, params);
        this.categories = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    categorySaved(data) {
      this.categories.push(data.category);
      this.categoriesSelected.push(data.category.id);
    },
    cancelSaveCategory() {
      this.dialog = false
    },
    setCategories() {
      if (this.productCategories) {
        this.categoriesSelected = []
        this.productCategories.forEach(element => {
          this.categoriesSelected.push(element)
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  watch: {
    categoriesSelected() {
      this.$emit("input", this.categoriesSelected);
    },
    productCategories: {
      deep: true,
      immediate: true,
      handler: 'setCategories'
    }
  },
};
</script>